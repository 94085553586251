import React, { useEffect, useCallback, useState } from "react";
import { useMoralis, useChain } from "react-moralis";
import { useTranslation } from "react-i18next";
import axios from "axios";
import useSettings from "../hooks/useSettings";
import { WALLET_CHAINS, TRANSACTION_RECEIVER } from "../config/appConfig";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify/react";
import closeFill from "@iconify-icons/eva/close-fill";
import configData from "../config.json";
import { IconButton } from "@mui/material";

const MAP_ERR_MSG = [
  {
    key: "Missing web3 instance",
    msg: "Please activate Metamask on browser",
  },
];
const MORALIS_MSG = [
  {
    key: "Missing web3 instance",
    msg:
      "Missing web3 instance. Please install Web3 extension for your browser.",
    translation: "web3_missing",
  },

  {
    code: "NETWORK_ERROR",
    msg: "Underlying network changed",
    translation: "web3_network_error",
  },
  {
    code: -32603,
    msg: "Insufficient funds for gas or metamask can foud the correct gas price, try again later.",
    translation: "web3_insufficient_gas",
  },
  {
    code: 4001,
    msg: "MetaMask Tx Signature: User denied transaction signature.",
    translation: "web3_user_denied",
  },
  {
    code: 4902,
    msg: "Unrecognized chain ID.  Try adding it in your wallet",
    translation: "web3_unrecognized",
  },
];
// ----------------------------------------------------------------------
export const WalletContext = React.createContext();

export default function WalletProvider({ ...props }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { wallet, selectDfChain } = useSettings();
  const {
    Moralis,
    authenticate,
    isAuthenticated,
    logout,
    user,
    account,
    web3,
    chainId,
    enableWeb3,
    isWeb3Enabled,
    isWeb3EnableLoading,
    authError,
  } = useMoralis();
  const {
    chain,
    switchNetwork,
    network,
    provider,
    connector,
    connectorType,
  } = useChain();
  const [isFetching, setisFetching] = useState(false);
  
 
  useEffect(() => {
    console.log("wallet provider:",wallet)
    console.log("wallet provider isWeb3Enabled:",isWeb3Enabled)
    if (!wallet) return;
    const { defaultNetwork } = wallet;
    if ( !isWeb3Enabled ) {
      console.log("account walletprovider:",account)
      var pr=window.localStorage.getItem("EProdiver");
      console.log("pr:",pr)
      if(pr!==undefined && pr!==null && account !==null){
        if(pr=="walletconnect"){
          console.log("walletconnect")

          enableWeb3({ provider: 'walletconnect', chainId: 97 })
        }
        else{
          console.log("metamask")
          enableWeb3();
          //switchNetwork("0x13881");
        
        
        }

      }


      
    }
  }, [wallet, isAuthenticated, isWeb3Enabled]);


  useEffect(() => {
    if (!authError) return;
    console.log("authError", authError);
  }, [authError]);

  const handleSelectConnector = useCallback(async (connectorId) => {
    try {
      console.log("connectorId:",connectorId)
      console.log("isAuthenticated:",isAuthenticated)
      if(connectorId==="0x38"){
        await authenticate({ provider: "walletconnect", chainId: 97 });
      ;
        await enableWeb3({ provider: "walletconnect"  });
        localStorage.setItem('EProdiver', "walletconnect");
       // await switchNetwork(connectorId);


      }
      else{
       // await authenticate({ provider: connectorId });
        localStorage.setItem('EProdiver', "metamask");
        await enableWeb3();

      //  await switchNetwork(connectorId);
     
     

      }


    } catch (e) {
      console.error(e.message);
      handleError(e);
    }
  }, []);

  const handleLogout = useCallback(async () => {
    await logout();
    localStorage.setItem("EProdiver","")
  }, []);



async function changeNetwork2(chainId){
  const provider = window.ethereum;
  const checkChainId = await provider.request({ method: "eth_chainId" });
  if(chainId !== checkChainId){
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainId}],
      });
    console.log("You have succefully switched ")
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
       console.log("This network is not available in your metamask, please add it")
      }
      console.log("Failed to switch to the network")
    }
  }


}

async function changeNetwork(chainId){
console.log("window.ethereum.networkVersion:",window.ethereum.networkVersion)
if (window.ethereum.networkVersion !== chainId) {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
     // params: [{ chainId: web3.utils.toHex(chainId) }]
     params: [{ chainId: (chainId) }]
    });
  } catch (err) {
      // This error code indicates that the chain has not been added to MetaMask
    if (err.code === 4902) {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainName: 'Polygon Mainnet',
            chainId:(chainId),
            nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
            rpcUrls: ['https://polygon-rpc.com/']
          }
        ]
      });
    }
    console.log("err:",err)
  }
}
}
  const handleBuyNft = async (puuid, amountInToken,geojson, chainIdSelected) => {
    try {
      if (!wallet){
        console.log("no wallet")
        return;
      } 
      console.log("no wallet.defaultChain:",wallet.defaultChain)

      console.log("no wallet.defaultNetwork:",wallet.defaultNetwork)

      if (!wallet.defaultChain) return;
      if (!wallet.defaultNetwork) return;
    
      setisFetching(true);
      const mintAddress = Moralis.account;
      let chainSelected = wallet.defaultChain.value;
      console.log("chainIdSelected:",chainIdSelected);
      console.log("wallet.defaultChain.value:",wallet.defaultChain.value);
      if (wallet.defaultChain.value !== chainIdSelected) {
        const chainFounded = WALLET_CHAINS.find((it) => {
          return it.value === chainIdSelected;
        });
      //   if (chainFounded) {
         
      //     chainSelected = chainFounded.value;
      //     selectDfChain(chainFounded);
      //   //const chainIdHex = await switchNetwork(chainIdSelected);
      // await  changeNetwork2(chainIdSelected)

       
      //   }else
      //   {
      //     console.log("Naaaaaaa")
      //   }
      }

      const response = await handleProcessChain(
        wallet.defaultChain.value,
        amountInToken
      );
      if (!response || !response.hash) {
        throw "Error on processing transaction";
      }
      const res = await axios.post(
        configData.SERVER_URL + configData.mintGenesis,
        {
          address: mintAddress,
          propertyUUID: puuid,
          blockchain: wallet.defaultChain.token,
          thash: response.hash,
          amount: amountInToken,
          geojson:geojson
        }
      );
      setisFetching(false);
    } catch (err) {
      console.error(err);
      setisFetching(false);
      throw err;
    }
  };
  const handleProcessChain = async (chainIdSelected, tilesSoldPrice) => {
    try {
      /*       if (wallet.defaultChain.value !== chainIdSelected) {
        const chainFounded = WALLET_CHAINS.find((it) => {
          return it.value === chainIdSelected;
        });
        console.log("tilesSold", chainFounded, chainIdSelected, tilesSoldPrice);
        if (chainFounded && chainFounded.value !== wallet.defaultChain.value) {
          selectDfChain(chainFounded);
          const chainIdHex = await Moralis.switchNetwork(chainIdSelected);
          console.log(
            "User metamask connected",
            chain,
            network,
            provider,
            connector,
            connectorType
          );
          // const chainIdHex = await switchNetwork(chainIdSelected);
          console.log("====================================");
          console.log("switchNetwork", chainIdHex);
          console.log("====================================");
        }
      } */
      setisFetching(true);
      console.log("tilesSoldPrice", tilesSoldPrice);

      try {
        const transaction = await Moralis.transfer({
          amount: Moralis.Units.Token(Number(tilesSoldPrice.toFixed(6)), "18"),
          receiver: TRANSACTION_RECEIVER,
          type: "native",
        });
        console.log("transaction", transaction);
        return transaction;
      } catch (error) {
        console.log("error transfer",error);
        console.error(error);
        throw error;
      }
      setisFetching(false);
      return true;
    } catch (err) {
      setisFetching(false);
      throw err;
    }
  };
  const handleError = (e) => {
    try {
      const msgMoralis = getMoralisMsg(e);
      if (msgMoralis) {
        enqueueSnackbar(msgMoralis, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          variant: "error",
          action: (key) => (
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </IconButton>
          ),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getMoralisMsg = (err) => {
    try {
      const { code, data, message } = err;

      const msgFounded = MORALIS_MSG.find((it) => {
        if (code) {
          return code === it.code;
        }
        return message.toUpperCase().includes(it.key.toUpperCase());
      });
      if (msgFounded) {
        return t(msgFounded.translation, msgFounded.msg);
      }
      return null;
    } catch (error) {
      return null;
    }
  };
  return (
    <WalletContext.Provider
      value={{
        handleBuyNft,
        handleProcessChain,
        handleSelectConnector,
        handleLogout,
        isFetching,
        handleError,
      }}
    >
      {props.children}
    </WalletContext.Provider>
  );
}
