import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import configData from '../../config.json';

// ----------------------------------------------------------------------

const initialState = {
	isLoading: false,
	isAuthenticated: false,
	role: 0,
	error: 0,
	message: '',
	user: {},
	token: '',
	initialized: false,
};
const slice = createSlice({
	name: 'authJwt',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true;
		},

		// INITIALISE
		getInitialize(state, action) {
			state.isLoading = false;
			state.isAuthenticated = action.payload.isAuthenticated;
			state.user = action.payload.user;
			state.token = action.payload.accessToken;
			state.initialized = true;
		},

		// LOGIN
		loginSuccess(state, action) {
			console.log("action:",action.payload)
			state.isAuthenticated = true;
			state.user = action.payload.user;
			state.role = action.payload.user.role;
			state.token = action.payload.accessToken;
			state.initialized = true;
		},

		// REGISTER
		registerSuccess(state, action) {
			state.isAuthenticated = true;
			state.user = action.payload.user;
			state.role = action.payload.user.role;
			state.token = action.payload.accessToken;
			state.initialized = true;
			state.message = null;
		},
		// REGISTER ERROR
		registerError(state, action) {
			state.isAuthenticated = false;
			state.error = action.payload.error;
			state.message = action.payload.message;
			state.initialized = true;
		},
		//Reset pwd
		resetPwdSuccess(state, action) {
			state.isAuthenticated = false;
			state.error = 0;
			state.message = 'done';
			state.initialized = true;
		},
		resetPwdError(state, action) {
			state.isAuthenticated = false;
			state.error = action.payload.error;
			state.message = action.payload.message;
			state.initialized = true;
		},
		// LOGOUT
		logoutSuccess(state) {
			state.isAuthenticated = false;
			state.user = null;
			state.initialized = true;
		},
		// Activate account
		activateSuccess(state, action) {
			state.message = null;
			state.error = null;
		},
	},
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
	if (!accessToken) {
		return false;
	}
	const decoded = jwtDecode(accessToken);
	const currentTime = Date.now() / 1000;

	return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
	if (accessToken) {
		localStorage.setItem('accessToken', accessToken);
		axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
	} else {
		localStorage.removeItem('accessToken');
		delete axios.defaults.headers.common.Authorization;
	}
};

// ----------------------------------------------------------------------
export function _deleteSession() {
	localStorage.removeItem('accessToken');
		delete axios.defaults.headers.common.Authorization;

}


export function extralogin({ tt }) {
	return async (dispatch) => {
		// const response = await axios.post(configData.SERVER_URL + configData.userLogin, {
		//  	email,
		//  	password,
		//  });

		 const response = await axios.post(configData.SERVER_URL + "/user/init", {
		 	ssoToken:tt,
			
		 });
		 console.log("response",response.data)
		const { accessToken, user } = response.data;
		console.log("login",accessToken, user)
		
		setSession(accessToken);
		dispatch(slice.actions.loginSuccess({ user, accessToken }));

	window.location.replace('/app/dashboard');
	};
}
export function login({ email, password }) {
	return async (dispatch) => {
		const response = await axios.post(configData.SERVER_URL + configData.userLogin, {
			email,
			password,
		});
		const { accessToken, user } = response.data;
		setSession(accessToken);
		dispatch(slice.actions.loginSuccess({ user, accessToken }));
	};
}

// ----------------------------------------------------------------------

export function register({ email, password, userName }) {
	return async (dispatch) => {
		try {
			
			const response = await axios.post(configData.SERVER_URL + configData.userRegister, {
				email,
				password,
				userName,
			});
			const { accessToken, user, error, message } = response.data;
			if (message && message === 'email already used') {
				dispatch(slice.actions.registerError({ message, error }));
			} else if (user && accessToken) {
				setSession(accessToken);
				dispatch(slice.actions.registerSuccess({ user, accessToken }));
			}
		} catch (error) {
			
			if (error.response) {
				let tmp = document.createElement('div');
				tmp.innerHTML = error.response.data.message;
				const msg = tmp.textContent || tmp.innerText || '';
				dispatch(slice.actions.registerError({ message: msg, error: error.response.status }));
			} else {
				dispatch(slice.actions.registerError({ message: 'Error ', error: '500' }));
			}
		}
	};
}
export function resetPwd({ email }) {
	return async (dispatch) => {
		try {
			const response = await axios.post(configData.SERVER_URL + configData.passwordRecover, {
				email,
			});
			const { error, message } = response.data;
			console.log('err', message);
			if (Number(error) === 0 && message === 'done') {
				dispatch(slice.actions.resetPwdSuccess({}));
			} else {
				dispatch(slice.actions.resetPwdError({ message, error }));
			}
		} catch (error) {
			if (error.response) {
				let tmp = document.createElement('div');
				tmp.innerHTML = error.response.data;
				const msg = tmp.textContent || tmp.innerText || '';
				dispatch(slice.actions.resetPwdError({ message: msg, error: error.response.status }));
			} else {
				dispatch(slice.actions.resetPwdError({ message: 'Error ', error: '500' }));
			}
		}
	};
}

export function validateAccount({ uuid, code }) {
	return async (dispatch) => {
		try {
			const response = await axios.post(configData.SERVER_URL + configData.userValidate, {
				uuid,
				code,
			});

			const { error, message } = response.data;

			if (Number(error) === 0 && message === 'done') {
				dispatch(slice.actions.activateSuccess({}));
			} else {
				dispatch(slice.actions.registerError({ message, error }));
			}
		} catch (error) {
			if (error.response) {
				let tmp = document.createElement('div');
				tmp.innerHTML = error.response.data;
				const msg = tmp.textContent || tmp.innerText || '';
				dispatch(slice.actions.registerError({ message: msg, error: error.response.status }));
			} else {
				dispatch(slice.actions.registerError({ message: 'Error ', error: '500' }));
			}
		}
	};
}
// ----------------------------------------------------------------------

export function logout() {
	return async (dispatch) => {
		setSession(null);
		dispatch(slice.actions.logoutSuccess());
	};
}

// ----------------------------------------------------------------------

export function getInitialize() {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());

		try {
			const accessToken = window.localStorage.getItem('accessToken');

			if (accessToken && isValidToken(accessToken)) {
				setSession(accessToken);
				const response = await axios.get(configData.SERVER_URL + configData.jwtAccount);
				dispatch(
					slice.actions.getInitialize({
						isAuthenticated: true,
						user: response.data.user,
						accessToken,
					})
				);
			} else {
				dispatch(
					slice.actions.getInitialize({
						isAuthenticated: false,
						user: null,
					})
				);
			}
		} catch (error) {
			console.error(error);
			dispatch(
				slice.actions.getInitialize({
					isAuthenticated: false,
					user: null,
				})
			);
		}
	};
}
