import faker from 'faker';
import mock from '../utils/mock';

// ----------------------------------------------------------------------
mock.onGet('/api/leaderboard/top').reply(() => {
  const leaders = [...Array(49)].map((leader, index) => {
    return {
      id: faker.datatype.uuid(),
      date: faker.date.recent(),
      rank: faker.datatype.number({ min: 1, max: 50, precision: 1 }),
      player: faker.internet.userName(),
      country: faker.address.countryCode(),
      credit: faker.finance.amount(),
    };
  });
  return [200, { leaders }];
});
