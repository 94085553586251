import faker from 'faker';
import mock from '../utils/mock';
import { getImgCover } from '../utils/getImages';

// ----------------------------------------------------------------------

const createId = (index) => `fc68bad5-d430-4033-b8f8-4bc069dc0ba0-${index}`;

// ----------------------------------------------------------------------

mock.onGet('/api/user/profile').reply(() => {
	const profile = {
		id: createId(1),
		cover: {
			small: getImgCover(720, 1),
			medium: getImgCover(1200, 1),
		},
		position: 'UI Designer',
		follower: faker.random.number(),
		following: faker.random.number(),
		quote: 'Tart I love sugar plum I love oat cake. Sweet roll caramels I love jujubes. Topping cake wafer..',
		country: faker.address.country(),
		email: faker.internet.email(),
		company: faker.company.companyName(),
		school: faker.company.companyName(),
		facebookLink: `https://www.facebook.com/caitlyn.kerluke`,
		instagramLink: `https://www.instagram.com/caitlyn.kerluke`,
		linkedinLink: `https://www.linkedin.com/in/caitlyn.kerluke`,
		twitterLink: `https://www.twitter.com/caitlyn.kerluke`,
	};

	return [200, { profile }];
});

mock.onGet('/api/user/account/cards').reply(() => {
	const cards = [...Array(4)].map((card, index) => {
		return {
			id: faker.datatype.uuid(),
			cardNumber:
				(index === 0 && '**** **** **** 1234') ||
				(index === 1 && '**** **** **** 5678') ||
				(index === 2 && '**** **** **** 6437') ||
				(index === 3 && '**** **** **** 1563'),
			cardType:
				(index === 0 && 'master_card') ||
				(index === 1 && 'visa') ||
				(index === 2 && 'paypal') ||
				(index === 3 && 'stripe'),
		};
	});

	return [200, { cards }];
});

// ----------------------------------------------------------------------

mock.onGet('/api/user/social/gallery').reply(() => {
	const gallery = [...Array(18)].map((image, index) => {
		const setIndex = index + 2;
		return {
			id: createId(setIndex),
			longitude: faker.address.longitude(),
			latitude: faker.address.latitude(),
			city: faker.address.city(),
			country: faker.address.country(),
			value: faker.finance.amount(),
			tiles: faker.datatype.number({ min: 1, max: 300 }),
			market: faker.datatype.number({ min: -100, max: 100, precision: 0.1 }),
			imageUrl:
				'https://images.unsplash.com/photo-1484589065579-248aad0d8b13?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=696&q=80',
		};
	});

	return [200, { gallery }];
});
