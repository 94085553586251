import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import configData from '../../config.json';

// ----------------------------------------------------------------------

const initialState = {
	isLoading: false,
	error: false,
	explorerMode: false,
	explorerResult: '',
	coordinateslong: 2,
	coordinateslat: 43,
	zoomLevel: 2,
	isEnable: false,
};

const slice = createSlice({
	name: 'explorer',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true;
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},

		isExplorerSuccess(state, action) {
			state.isLoading = false;
			state.explorerMode = action.payload;
		},
		isExplorerResultSuccess(state, action) {
			state.isLoading = false;
			state.explorerResult = action.payload;
			state.isEnable = true;
		},
		isenableSuccess(state, action) {
			state.isLoading = false;

			state.isEnable = true;
		},
		isSaveCoordinatesSuccess(state, action) {
			state.isLoading = false;

			state.coordinateslong = action.payload.coordinateslong;
			state.coordinateslong = action.payload.coordinateslat;
			state.coordinates = action.payload.zoom;
		},
	},
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;/* eslint-disable-line */

export function getenable(boo) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			dispatch(slice.actions.isenableSuccess(boo));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getexplorerResult(boo) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			dispatch(slice.actions.isExplorerResultSuccess(boo));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function isExplorer(boo) {
	console.log('hello is Explorer');
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			dispatch(slice.actions.isExplorerSuccess(boo));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function saveCoordinates(coordinateslong, coordinateslat, zoom) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			var payload = { coordinateslong: coordinateslong, coordinateslat: coordinateslat, zoom: zoom };
			dispatch(slice.actions.isSaveCoordinatesSuccess(payload));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getLeaders() {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.get(configData.SERVER_URL + configData.getLeaderboard);
			// console.log(response);
			dispatch(slice.actions.getLeadersSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
