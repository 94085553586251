import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { PATH_HOME ,PATH_PAGE} from './paths';

// ----------------------------------------------------------------------

const HomeRoutes = {
	path: '*',
	routes: [
		{
			exact: true,
			path: '/',
			component: lazy(() => import('../pages/HomePage/HomePage')),
		},
		{
			exact: true,
			path: PATH_HOME.about,
			component: lazy(() => import('../pages/Aboutpage/AboutPage')),
		},
		// {
		// 	exact: true,
		// 	path: PATH_PAGE.auth.register,
		// 	component: lazy(() => import('../pages/RegisterPage/RegisterPage')),
		// },
		{
			exact: true,
			path: PATH_PAGE.auth.login,
			component: lazy(() => import('../pages/LoginPage/LoginPage')),
		},
		// News
		// ----------------------------------------------------------------------
		{
			exact: true,
			path: PATH_HOME.news,

			component: lazy(() => import('../pages/NewsPage/NewsPage')),
		},
		{
			component: () => <Redirect to="/404" />,
		},
	],
};

export default HomeRoutes;
