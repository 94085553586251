import { map, sum } from 'lodash';
import aaxios from '../../utils/axios';
import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import configData from '../../config.json';
// ----------------------------------------------------------------------

const initialState = {
	autoCommit:{},
	isLoading: false,
	error: false,
	userMoney: null,
	externalProfil: null,
	userinfodetails: [],
	posts: [],
	users: [],
	userList: [],
	followers: [],
	friends: [],
	gallery: [],
	cards: null,
	addressBook: [],
	stakingOffers:[],
	stakingList:[],
	invoices: [],
	notifications: null,
	propeties: [],
	sortBy: null,
	checkout: {
		credit: 0,
		subtotal: 0,
		total: 0,
		discount: 0,
		billing: null,
	},
};

const slice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true;
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},
		getUserMoneySuccess(state, action) {
			state.isLoading = false;
			state.userMoney = action.payload;
		},
		// GET PROFILE
		getProfileSuccess(state, action) {
			state.isLoading = false;
			state.myProfile = action.payload;
		},
		getExternalProfileSuccess(state, action) {
			state.isLoading = false;
			state.externalProfil = action.payload;
		},
		// GET POSTS
		getPostsSuccess(state, action) {
			state.isLoading = false;
			state.posts = action.payload;
		},

		// GET USERS
		getUsersSuccess(state, action) {
			state.isLoading = false;
			state.users = action.payload;
		},

		// GET FOLLOWERS
		getFollowersSuccess(state, action) {
			state.isLoading = false;
			state.followers = action.payload;
		},

		// ON TOGGLE FOLLOW
		onToggleFollow(state, action) {
			const followerId = action.payload;

			const handleToggle = map(state.followers, (follower) => {
				if (follower.id === followerId) {
					return {
						...follower,
						isFollowed: !follower.isFollowed,
					};
				}
				return follower;
			});

			state.followers = handleToggle;
		},

		// GET FRIENDS
		getFriendsSuccess(state, action) {
			state.isLoading = false;
			state.friends = action.payload;
		},

		// GET ALL PROPERTIES
		getAllPropertiesSuccess(state, action) {
			state.isLoading = false;
			state.properties = action.payload;
		},

		//SORT & FILTER PROPERTIES
		sortByProperties(state, action) {
			state.sortBy = action.payload;
		},

		// GET GALLERY
		getGallerySuccess(state, action) {
			state.isLoading = false;
			state.gallery = action.payload;
		},

		// GET CARDS
		getCardsSuccess(state, action) {
			state.isLoading = false;
			state.cards = action.payload;
		},

		// GET ADDRESS BOOK
		getAddressBookSuccess(state, action) {
			state.isLoading = false;
			state.addressBook = action.payload;
		},

		// GET INVOICES
		getInvoicesSuccess(state, action) {
			state.isLoading = false;
			state.invoices = action.payload;
		},
		getUserDetailsInfosSuccess(state, action) {
			state.isLoading = false;
			state.userinfodetails = action.payload;
		},
		getUserListSuccess(state, action) {
			state.isLoading = false;
			state.userList = action.payload;
		},
		// GET NOTIFICATIONS
		getNotificationsSuccess(state, action) {
			state.isLoading = false;
			state.notifications = action.payload;
		},
		getAllStakingOffersSuccess(state, action) {
			state.isLoading = false;
			state.stakingOffers = action.payload;
		},
		getStakingListByUserSuccess(state, action) {
			state.isLoading = false;
			state.stakingList = action.payload;
		},
		getAutoCommitSuccess(state, action) {
			state.isLoading = false;
			state.autoCommit = action.payload;
		},
		
		// CHECKOUT
		getCredit(state, action) {
			const credit = action.payload;

			const subtotal = sum(credit);
			const discount = credit === 0 ? 0 : state.checkout.discount;
			const billing = credit === 0 ? null : state.checkout.billing;

			state.checkout.credit = credit;
			state.checkout.discount = discount;
			state.checkout.billing = billing;
			state.checkout.subtotal = subtotal;
			state.checkout.total = subtotal - discount;
		},

		createBilling(state, action) {
			state.checkout.billing = action.payload;
		},

		applyDiscount(state, action) {
			const discount = action.payload;
			state.checkout.discount = discount;
			state.checkout.total = state.checkout.subtotal - discount;
		},
	},
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, getCredit, createBilling, applyDiscount, sortByProperties } = slice.actions;

export function getUserDetailsInfos(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.get(configData.SERVER_URL + configData.getUserInfoDetails);
			dispatch(slice.actions.getUserDetailsInfosSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getExternalProfile(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getExternaluserInfos, {
				uuid: uuid,
			});

			dispatch(slice.actions.getExternalProfileSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getUserMoney(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getUserMoney, {
				uuid: uuid,
			});
			dispatch(slice.actions.getUserMoneySuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getProfile(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			if (!axios.defaults.headers.common.Authorization) return;
			const response = await axios.get(configData.SERVER_URL + configData.getuserInfos);
			dispatch(slice.actions.getProfileSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
/* new mecano to update amount */
export function updateUserBank(newUserMoney) {
	return async (dispatch) => {
		try {
			if (newUserMoney) dispatch(slice.actions.getUserMoneySuccess(newUserMoney));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
// ----------------------------------------------------------------------

export function getPosts() {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.get('/api/user/posts');
			dispatch(slice.actions.getPostsSuccess(response.data.posts));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

// ----------------------------------------------------------------------

export function getFollowers() {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.get('/api/user/social/followers');
			dispatch(slice.actions.getFollowersSuccess(response.data.followers));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

// ----------------------------------------------------------------------

export function getFriends() {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.get('/api/user/social/friends');
			dispatch(slice.actions.getFriendsSuccess(response.data.friends));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

// ----------------------------------------------------------------------

export function getGallery(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getAllProperties, {
				uuid: uuid,
			});

			dispatch(slice.actions.getGallerySuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

// ------------------------------get----------------------------------------

export function getUserList() {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.get(configData.SERVER_URL + configData.getUserList);
			dispatch(slice.actions.getUserListSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

// ----------------------------------------------------------------------

export function getCards() {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await aaxios.get('/api/user/account/cards');
			dispatch(slice.actions.getCardsSuccess(response.data.cards));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

// ----------------------------------------------------------------------

export function getAddressBook(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.get(configData.SERVER_URL + configData.getAddressBook);

			dispatch(slice.actions.getAddressBookSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

// ----------------------------------------------------------------------

export function getInvoices(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getTransactionByUser, {
				uuid: uuid,
			});

			dispatch(slice.actions.getInvoicesSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

// ----------------------------------------------------------------------

export function getNotifications() {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.get('/api/user/account/notifications-settings');
			dispatch(slice.actions.getNotificationsSuccess(response.data.notifications));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

// ----------------------------------------------------------------------

export function getUsers() {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.get('/api/user/all');
			dispatch(slice.actions.getUsersSuccess(response.data.users));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

// ----------------------------------------------------------------------

export function getAllProperties(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getAllProperties, {
				uuid: uuid,
			});
			dispatch(slice.actions.getAllPropertiesSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getAllStakingOffers(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getAllStakingOffers);
			dispatch(slice.actions.getAllStakingOffersSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}


export function getAutoCommit() {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getAutoCommit);
			dispatch(slice.actions.getAutoCommitSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}



export function getStakingListByUser(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getStakingListByUser, {
				uuid: uuid,
			});
			dispatch(slice.actions.getStakingListByUserSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
