import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import configData from '../../config.json';
// ----------------------------------------------------------------------

const initialState = {
	isLoading: false,
	isLoadingItem: false,
	error: false,
	markets: [],
	immaterialRessources: [],
	ressources: [],
	myChampions: [],
	mySupporters: [],
	myExplorers: [],
	myFollowers: [],
	activities: [],
	champions: [],
	supporters: [],
	explorers: [],
	followers: [],
	myInsight: [],
	insightTree: [],
	myTechno: [],
	techTree: [],
	teams: [],
	conclaves: [],
	ddSupporters: [],
	ddChampions: [],
	ddFollowers: [],
	ddExplorers: [],
	tilePrice: 0,
	stuffChampions: [],
	stuffSupporters: [],
	stuffExplorers: [],
	stuffFollowers: [],
	specialItem: [],
	specialItems: [],
	equipmentsActivity: [],
};

const slice = createSlice({
	name: 'market',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true;
		},
		startLoadingItem(state) {
			state.isLoadingItem = true;
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},
		// GET COUNTRIES
		getRessourcesSuccess(state, action) {
			state.isLoading = false;
			state.ressources = action.payload;
		},
		getImmaterialRessourcesSuccess(state, action) {
			state.isLoading = false;
			state.immaterialressources = action.payload;
		},
		getSupportersSuccess(state, action) {
			state.isLoading = false;
			state.supporters = action.payload;
		},
		getActivitiesSuccess(state, action) {
			state.isLoading = false;
			state.activities = action.payload;
		},

		getExplorersSuccess(state, action) {
			state.isLoading = false;
			state.explorers = action.payload;
		},
		getFollowersSuccess(state, action) {
			state.isLoading = false;
			state.followers = action.payload;
		},
		getChampionsSuccess(state, action) {
			state.isLoading = false;
			state.champions = action.payload;
		},
		getMyExplorersSuccess(state, action) {
			state.isLoading = false;
			state.myExplorers = action.payload;
		},
		getMyFollowersSuccess(state, action) {
			state.isLoading = false;
			state.myFollowers = action.payload;
		},
		getMyChampionsSuccess(state, action) {
			state.isLoading = false;
			state.myChampions = action.payload;
		},
		getMySupportersSuccess(state, action) {
			state.isLoading = false;
			state.mySupporters = action.payload;
		},
		// GET COUNTRIES
		getMarketsSuccess(state, action) {
			state.isLoading = false;
			state.markets = action.payload;
		},
		// Tile Price
		tilePriceSuccess(state, action) {
			state.isLoading = false;
			state.tilePrice = action.payload;
		},
		getInsightSuccess(state, action) {
			state.isLoading = false;
			state.myInsight = action.payload;
		},
		// Tile Price
		getTechnoSuccess(state, action) {
			state.isLoading = false;
			state.myTechno = action.payload;
		},

		// Tile Price
		getTeamsSuccess(state, action) {
			state.isLoading = false;
			state.teams = action.payload;
		},
		// Tile Price
		getConclavesSuccess(state, action) {
			state.isLoading = false;
			state.conclaves = action.payload;
		},
		// Tile Price
		getListEquipmentsActivitySuccess(state, action) {
			state.isLoading = false;
			state.equipmentsActivity = action.payload;
		},

		// Tile Price
		getSpecialItemSuccess(state, action) {
			state.isLoadingItem = false;
			state.specialItem = action.payload;
		},
		getListSpecialItemsSuccess(state, action) {
			state.isLoading = false;
			state.specialItems = action.payload;
		},

		getListStuffExplorersSuccess(state, action) {
			state.isLoading = false;
			state.stuffExplorers = action.payload;
		},
		getListStuffFollowersSuccess(state, action) {
			state.isLoading = false;
			state.stuffFollowers = action.payload;
		},
		getListStuffSupportersSuccess(state, action) {
			state.isLoading = false;
			state.stuffSupporters = action.payload;
		},
		getTechTreeSuccess(state, action) {
			state.isLoading = false;
			state.techTree = action.payload;
		},
		getInsightTreeSuccess(state, action) {
			state.isLoading = false;
			state.insightTree = action.payload;
		},
		getcreateDropDownSuccess(state, action) {
			state.isLoading = false;
			state.ddChampions = action.payload.champions;
			state.ddSupporters = action.payload.supporters;
		},
		getcreateDropDownConclaveSuccess(state, action) {
			state.isLoading = false;
			state.ddExplorers = action.payload.explorers;
			state.ddFollowers = action.payload.followers;
		},
		// Tile Price
		getListStuffChampionsSuccess(state, action) {
			state.isLoading = false;
			state.stuffChampions = action.payload;
		},
	},
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions; /* eslint-disable-line */
////////////////////
export function getcreateDropDown(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getListChampion, {
				uuid: uuid,
			});
			console.log(response.data);
			dispatch(slice.actions.getcreateDropDownSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getcreateDropDownConclave(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getListExplorer, {
				uuid: uuid,
			});
			console.log(response.data);
			dispatch(slice.actions.getcreateDropDownConclaveSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getTechTree(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			// const response = await axios.post(configData.SERVER_URL + configData.showTechTree, {
			// 	uuid: uuid,
			// });
			const response = await axios.post("https://api.earthium.io/showTechTree", {
				uuid: uuid,
			});
			dispatch(slice.actions.getTechTreeSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

////////////////////
export function getInsight(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getmyInsights, {
				uuid: uuid,
			});
			dispatch(slice.actions.getInsightSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getInsightTree(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.showInsightTree, {
				uuid: uuid,
			});
			dispatch(slice.actions.getInsightTreeSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

////////////////////
export function getTechno(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getmyTechnos, {
				uuid: uuid,
			});

			dispatch(slice.actions.getTechnoSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getConclaves(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getConclaves, {
				uuid: uuid,
			});
			dispatch(slice.actions.getConclavesSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getTeams(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getTeams, {
				uuid: uuid,
			});
			dispatch(slice.actions.getTeamsSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getListEquipmentsActivity(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getListEquipmentsActivity, {
				uuid: uuid,
			});
			dispatch(slice.actions.getListEquipmentsActivitySuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getSpecialItem(uuid,rid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoadingItem());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getListSpecialItem, {
				uuid: uuid,
				rid:rid
			});
			dispatch(slice.actions.getSpecialItemSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getListSpecialItems(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getListSpecialItems, {
				uuid: uuid,
				
			});
			dispatch(slice.actions.getListSpecialItemsSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getListStuffExplorers(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getListStuffExplorers, {
				uuid: uuid,
			});
			dispatch(slice.actions.getListStuffExplorersSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getListStuffSupporters(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getListStuffSupporters, {
				uuid: uuid,
			});
			console.log(response);
			dispatch(slice.actions.getListStuffSupportersSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getListStuffFollowers(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getListStuffFollowers, {
				uuid: uuid,
			});

			dispatch(slice.actions.getListStuffFollowersSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getListStuffChampions(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getListStuffChampions, {
				uuid: uuid,
			});
			dispatch(slice.actions.getListStuffChampionsSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
/////://////////////:
export function getMySupporters(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getSupporters, {
				uuid: uuid,
			});
			dispatch(slice.actions.getMySupportersSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getMyChampions(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			 const response = await axios.post(configData.SERVER_URL + configData.getChampions, {
				uuid: uuid,
			 });
		
	
			dispatch(slice.actions.getMyChampionsSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getMyExplorers(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getExplorers, {
				uuid: uuid,
			});
			console.log(response);
			dispatch(slice.actions.getMyExplorersSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getMyFollowers(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getFollowers, {
				uuid: uuid,
			});
			console.log(response);
			dispatch(slice.actions.getMyFollowersSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getImmaterialRessources(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getListImmaterialRessources, {
				uuid: uuid,
			});
			dispatch(slice.actions.getImmaterialRessourcesSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getRessources(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getListRessources, {
				uuid: uuid,
			});
			dispatch(slice.actions.getRessourcesSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getSupporters(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getListSupporters, {
				uuid: uuid,
			});
			dispatch(slice.actions.getSupportersSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getFollowers(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getListFollowers, {
				uuid: uuid,
			});
			dispatch(slice.actions.getFollowersSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getChampions(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			 const response = await axios.post(configData.SERVER_URL + configData.getListChampions, {
			 	uuid: uuid,
			 });
			// const response = await axios.post("https://api.earthium.io/getListChampions", {
			// 	uuid: uuid,
			// });
			dispatch(slice.actions.getChampionsSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getExplorers(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getListExplorers, {
				uuid: uuid,
			});
			dispatch(slice.actions.getExplorersSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getActivities(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getListActivities, {
				uuid: uuid,
			});
			dispatch(slice.actions.getActivitiesSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getMarket() {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.get(configData.SERVER_URL + configData.marketPlace2);

			dispatch(slice.actions.getMarketsSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

// ----------------------------------------------------------------------

export function getTilePrice(id) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getSettings, {
				id: id,
			});
			// console.log(response.data);
			dispatch(slice.actions.tilePriceSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
