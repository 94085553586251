
import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import Logo from './Logo';

// ----------------------------------------------------------------------

const TRANSITION = {
  ease: 'linear',
  duration: 3.2,
  loop: Infinity
};

// ----------------------------------------------------------------------

LoadingScreen.propTypes = {
  className: PropTypes.string
};

function LoadingScreen({ className, ...other }) {

  return (
    <div style={{height: `calc(100vh - 85px)`, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor:'#161C24'}}>
      <motion.div
        initial={{ rotateY: 0 }}
        animate={{ rotateY: 360 }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          flip: Infinity,
          repeatDelay: 1
        }}
      >
        <Logo sx={{ height: 64 }} />
      </motion.div>

      <motion.div
        animate={{
          scale: [1.2, 1, 1, 1.2, 1.2],
          rotate: [270, 0, 0, 270, 270],
          opacity: [0.25, 1, 1, 1, 0.25],
          borderRadius: ['25%', '25%', '50%', '50%', '25%']
        }}
        transition={TRANSITION}
        style={{position: 'absolute',  borderRadius: '25%',width: 100,
        height: 100,
        border: 'solid 3px #212B36' }}
      />

      <motion.div
        animate={{
          scale: [1, 1.2, 1.2, 1, 1],
          rotate: [0, 270, 270, 0, 0],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          borderRadius: ['25%', '25%', '50%', '50%', '25%']
        }}
        transition={{
          ease: 'linear',
          duration: 3.2,
          loop: Infinity
        }}
        style={{position: 'absolute',  borderRadius: '25%',width: 120,
        height: 120,
        border: 'solid 8px #2a2a09' }}
      />
    </div>
  );
}

export default LoadingScreen;
