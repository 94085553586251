import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './slices/user';
import authJwtReducer from './slices/authJwt';
import settingsReducer from './slices/settings';
import leaderboardReducer from './slices/leaderboard';
import marketReducer from './slices/market';
import drawerReducer from './slices/drawer';
import explorerReducer from './slices/explorer';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['settings'],
};

const authPersistConfig = {
  key: 'authJwt',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated'],
};

const rootReducer = combineReducers({
  drawer: drawerReducer,
  explorer: explorerReducer,
  leaderboard: leaderboardReducer,
  market: marketReducer,
  user: userReducer,
  settings: settingsReducer,
  authJwt: persistReducer(authPersistConfig, authJwtReducer),
});

export { rootPersistConfig, rootReducer };
