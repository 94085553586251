import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	className: PropTypes.string,
};

function Logo({ className, height, ...other }) {
	return (
		<Box
			className="logo_navbar"
			component="img"
			alt="logo"
			src="/static/brand/logo_navbar.png"
			height={height ? height : 63}
		/>
	);
}

export default Logo;
