import NProgress from 'nprogress';
import AppRoutes from './AppRoutes';
import { PATH_PAGE } from './paths';
import HomeRoutes from './HomeRoutes';
import LoadingScreen from '../components/LoadingScreen';
import { Switch, Route } from 'react-router-dom';
import React, { Suspense, Fragment, lazy, useEffect } from 'react';

// ----------------------------------------------------------------------


function RouteProgress(props) {

	NProgress.configure({
		speed: 500,
		showSpinner: false,
	});

	useEffect(() => {
		NProgress.done();
		return () => {
			NProgress.start();
		};
	}, []);

	return <Route {...props} />;
}

export function renderRoutes(routes = []) {
	return (
		<Suspense fallback={<LoadingScreen />}>
			<Switch>
				{routes.map((route, i) => {
					const Component = route.component;
					const Guard = route.guard || Fragment;
					const Layout = route.layout || Fragment;
					return (
						<RouteProgress
							key={i}
							path={route.path}
							exact={route.exact}
							render={(props) => (
								<Guard>
									<Layout>
										{route.routes ? renderRoutes(route.routes) : <Component {...props} />}
									</Layout>
								</Guard>
							)}
						/>
					);
				})}
			</Switch>
		</Suspense>
	);
}

const routes = [
	{
		exact: true,
		path: PATH_PAGE.auth.resetPassword,
		component: lazy(() => import('../pages/ForgotPasswordPage/ForgotPasswordPage')),
	},
	{
		exact: true,
		path: PATH_PAGE.auth.recoverPassword + '/:token',
		component: lazy(() => import('../pages/ForgotPasswordPage/RecoverPassword')),
	},
	
	{
		exact: true,
		path: '/404',
		component: lazy(() => import('../pages/errors/Page404View')),
	},
	{
		exact: true,
		path: '/500',
		component: lazy(() => import('../pages/errors/Page500View')),
	},
	{
		exact: true,
		path: '/wait',
		component: lazy(() => import('../pages/HomePage/HomePage')),
	},
	AppRoutes,
	HomeRoutes,
];

export default routes;
