import { PATH_APP } from "./paths";
import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import AuthProtect from "../components/Auth/AuthProtect";

// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_APP.root,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.general.dashboard,
      component: lazy(() => import("../pages/general/DashboardAppView")),
    },
    {
      exact: true,
      path: PATH_APP.general.dashboard + "/:lng/:lnt/:zoom",
      component: lazy(() => import("../pages/general/DashboardAppView")),
    },
    {
      exact: true,
      path: PATH_APP.general.dashboard + "/:lng/:lnt/:zoom/:idprops",
      component: lazy(() => import("../pages/general/DashboardAppView")),
    },
    {
      exact: true,
      path: PATH_APP.root,
      component: () => <Redirect to={PATH_APP.general.root} />,
    },
    {
      guard: AuthProtect,
      exact: true,
      path: PATH_APP.management.user.external + "/:uuid",
      component: lazy(() => import("../pages/User/ProfileViewExt")),
    },
    // MARKETPLACE
    // ----------------------------------------------------------------------
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.market.marketplace,
    //   component: lazy(() => import("../pages/MarketplacePage/MarketplacePage")),
    // },
    // About
    // ----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_APP.general.about,
      component: lazy(() => import("../pages/Aboutpage/AboutPage")),
    },
    {
      exact: true,
      path: PATH_APP.general.about + "/:key",
      component: lazy(() => import("../pages/Aboutpage/AboutPage")),
    },
    // News
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.news.home,
      component: lazy(() => import("../pages/NewsPage/NewsPage")),
    }, // USER
    {
      exact: true,
      path: PATH_APP.privacyPolicy.privacyPolicy,
      component: lazy(() => import("../pages/PrivacyPage/PrivacyPage")),
    }, 
    {
      exact: true,
      path: PATH_APP.cookies.cookies,
      component: lazy(() => import("../pages/CookiesPage/CookiesPage")),
    }, 
    // ----------------------------------------------------------------------
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.management.user.profile,
    //   component: lazy(() => import("../pages/ProfilePage/ProfilePage")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.management.user.profile + "/:profil",
    //   component: lazy(() => import("../pages/ProfilePage/ProfilePage")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.management.user.newProfile,
    //   component: lazy(() => import("../pages/ProfilePage/ProfilePage")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.management.user.money,
    //   component: lazy(() => import("../pages/MoneyPage/MoneyPage")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.management.user.money + "/:key",
    //   component: lazy(() => import("../pages/MoneyPage/MoneyPage")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.management.user.settings,
    //   component: lazy(() =>
    //     import("../pages/SettingsGeneralPage/SettingsGeneralPage")
    //   ),
    // },
     {
       guard: AuthProtect,
       exact: true,
       path: PATH_APP.management.user.assets,
       component: lazy(() => import("../pages/AssetsPage/AssetsPage")),
     },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.transaction.transaction,
    //   component: lazy(() => import("../pages/TransactionPage/TransactionPage")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.transaction.transaction + "/account/:key",
    //   component: lazy(() => import("../pages/TransactionPage/TransactionPage")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.management.user.root,
    //   component: () => <Redirect to={PATH_APP.management.user.profile} />,
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.management.root,
    //   component: () => <Redirect to={PATH_APP.management.user.profile} />,
    // },
    // RESSOURCE
    // ----------------------------------------------------------------------
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.resource.resource,
    //   component: lazy(() => import("../pages/RareResourcesPage/RareResourcesPage")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.immaterialresource.immaterialresource,
    //   component: lazy(() =>
    //     import("../pages/ImmaterialPage/ImmaterialPage")
    //   ),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.cityMarket.cityMarket,
    //   component: lazy(() =>
    //     import("../pages/CityMarketPage/CityMarketPage")
    //   ),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.ranking.ranking,
    //   component: lazy(() =>
    //     import("../pages/RankingPage/RankingPage")
    //   ),
    // },
    {
      guard: AuthProtect,
      exact: true,
      path: PATH_APP.admin.view,
      component: lazy(() =>
        import("../pages/AdminPage/AdminPage")
      ),
    },
 
    // RESSOURCE
    // ----------------------------------------------------------------------
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.supporter.supporter,
    //   component: lazy(() => import("src/views/supporter/SupporterView")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.follower.follower,
    //   component: lazy(() => import("src/views/follower/FollowerView")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.myfollower.myfollower,
    //   component: lazy(() => import("src/views/myfollower/MyFollowerView")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.stuffFollowers.stuffFollowers,
    //   component: lazy(() =>
    //     import("src/views/stuffFollowers/StuffFollowersView")
    //   ),
    // },

    // // RESSOURCE
    // // ----------------------------------------------------------------------
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.activity.activity,
    //   component: lazy(() => import("src/views/activity/ActivityView")),
    // },
    // // RESSOURCE
    // // ----------------------------------------------------------------------
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.mychampion.mychampion,
    //   component: lazy(() => import("src/views/mychampion/MyChampionView")),
    // },
    // // RESSOURCE
    // // ----------------------------------------------------------------------
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.mysupporter.mysupporter,
    //   component: lazy(() => import("src/views/mysupporter/MySupporterView")),
    // },
    // // RESSOURCE
    // // ----------------------------------------------------------------------
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.champion.champion,
    //   component: lazy(() => import("src/views/champion/ChampionView")),
    // },
    // // ----------------------------------------------------------------------
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.stuffChampions.stuffChampions,
    //   component: lazy(() =>
    //     import("src/views/stuffChampions/StuffChampionsView")
    //   ),
    // },
    // // ----------------------------------------------------------------------
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.stuffSupporters.stuffSupporters,
    //   component: lazy(() =>
    //     import("src/views/stuffSupporters/StuffSupportersView")
    //   ),
    // },
    // // ----------------------------------------------------------------------

    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.specialItems.specialItems,
    //   component: lazy(() =>
    //     import("../pages/SpecialItemsPage/SpecialItemsPage")
    //   ),
    // },

    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.units.units,
    //   component: lazy(() => import("../pages/UnitsPage/UnitsPage")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.units.unitsNew,
    //   component: lazy(() => import("src/views/UnitsPage/UnitsPage")),
    // },

    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.ressources.ressources,
    //   component: lazy(() => import("../pages/RareResourcesPage/RareResourcesPage")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.supporters.supporters,
    //   component: lazy(() => import("src/views/game/SupportersView")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.myChampions.myChampions,
    //   component: lazy(() => import("src/views/game/MyChampionsView")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.mySupporters.mySupporters,
    //   component: lazy(() => import("src/views/game/MySupportersView")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.myChampionStuff.myChampionStuff,
    //   component: lazy(() => import("src/views/game/StuffChampionView")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.mySupporterStuff.mySupporterStuff,
    //   component: lazy(() => import("src/views/game/StuffSupporterView")),
    // },

    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.enigma.enigma,
    //   component: lazy(() => import("../pages/EnigmaPage/EnigmaPage")),
    // },
    // ----------------------------------------------------------------------
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.equipmentActivity.equipmentActivity,
    //   component: lazy(() =>
    //     import("src/views/equipmentActivity/EquipmentActivityView")
    //   ),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.conclaves.conclaves,
    //   component: lazy(() => import("src/views/conclaves/ConclavesView")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.conclaves.createConclave,
    //   component: lazy(() => import("src/views/createConclave/")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.teams.teams,
    //   component: lazy(() => import("src/views/teams/TeamsView")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.mytechno.mytechno,
    //   component: lazy(() => import("src/views/technoKnown/TechnoKnownView")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.techtree.techtree,
    //   component: lazy(() => import("../pages/TechTreePage/TechTreePage")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.teams.createTeam,
    //   component: lazy(() => import("src/views/createTeam")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.explorer.explorer,
    //   component: lazy(() => import("src/views/explorer/ExplorerView")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.myexplorer.myexplorer,
    //   component: lazy(() => import("src/views/myExplorer/MyExplorerView")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.stuffExplorers.stuffExplorers,
    //   component: lazy(() =>
    //     import("src/views/stuffExplorers/StuffExplorersView")
    //   ),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.myinsight.myinsight,
    //   component: lazy(() => import("src/views/insightKnown/InsightKnownView")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.insighttree.insighttree,
    //   component: lazy(() => import("src/views/insightTree/InsightTreeView")),
    // },

    // LEADERBOARD
    // ----------------------------------------------------------------------
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.leader.leaderboard,
    //   component: lazy(() => import("../pages/LeaderboardPage/LeaderboardPage")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.staking.home,

    //   component: lazy(() => import("../pages/StakingPage/StakingPage")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.nft.home,

    //   component: lazy(() => import("../pages/NFTPage/NFTPage")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.nft.nftPosibility,
    //   component: lazy(() => import("../web3/NftPossibility")),
    // },
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.nft.home + "/:tokenId/:typeTile",
    //   component: lazy(() => import("../pages/NFTPage/NFTPage")),
    // },
    
    // {
    //   guard: AuthProtect,
    //   exact: true,
    //   path: PATH_APP.nft.nftPosibility + "/:puid",
    //   component: lazy(() => import("../web3/PropNftDetail")),
    // },
    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />,
    },
  ],
};

export default AppRoutes;
