import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import configData from '../../config.json';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  leaders: [],
  leader: null,
  sortBy: null,
};

const slice = createSlice({
  name: 'leaderboards',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TOP 50 LEADERS
    getLeadersSuccess(state, action) {
      state.isLoading = false;
      state.leaders = action.payload;
    },

    // GET LEADER
    getLeaderSuccess(state, action) {
      state.isLoading = false;
      state.leader = action.payload;
    },

    // SORT & FILTER LEADERS
    sortByLeader(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByLeader } = slice.actions;

 
export function getLeaders() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
        const response = await axios.get(
        configData.SERVER_URL + configData.getLeaderboard
      );

      dispatch(slice.actions.getLeadersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
