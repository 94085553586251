import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MoralisProvider } from 'react-moralis';
import { store, persistor } from './redux/store';
import Web3 from 'web3';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LoadingScreen from './components/LoadingScreen';
import WalletProvider from './provider/WalletProvider';
import ScrollToTop from './components/ScrollToTop';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { createBrowserHistory } from 'history';
import { Web3ReactProvider } from '@web3-react/core';
import JwtProvider from './components/Auth/JwtProvider';
import MapProvider from './provider/MapProvider';
import NotistackProvider from './components/NotistackProvider';
import './Fonts/Bios.woff2'
import routes, { renderRoutes } from './routes';
import RoutesProvider from './provider/RoutesProvider';

function getLibrary(provider) {
	return new Web3(provider,"any");
}

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: "rgb(182, 150, 58)",
    },
    secondary: {
      main: 'rgb(227, 79, 113)',
    },
  },
  typography: {
    fontFamily: 'Aldrich',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
 
 
});

const history = createBrowserHistory();

function App() {
  return (
    <div className="app">
      <MoralisProvider
				appId="pbjez1RvJa00LfEGh5D9gbUk7vedKbJtpA4mKDiS"
				serverUrl="https://vqdhbf9olbjf.grandmoralis.com:2053/server"
			>
        <Web3ReactProvider getLibrary={getLibrary}>
          <Provider store={store}>
            <ThemeProvider theme={darkTheme}>
              <PersistGate loading={<LoadingScreen />} persistor={persistor}>
                <NotistackProvider>
                  <Router history={history}>
                    <JwtProvider>
                      <ScrollToTop />
					              <WalletProvider>
                          <MapProvider>          
                            <RoutesProvider>{renderRoutes(routes)}</RoutesProvider>
                          </MapProvider>
                        </WalletProvider>
                    </JwtProvider>
                  </Router>
                </NotistackProvider>
              </PersistGate>
            </ThemeProvider>
          </Provider>
        </Web3ReactProvider>
      </MoralisProvider>
    </div>
  );
}

export default App;
