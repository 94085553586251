import { createSlice } from '@reduxjs/toolkit';
import { WALLET_CHAINS, WALLET_CONNECTORS } from '../../config/appConfig';

// ----------------------------------------------------------------------

const initialState = {
	wallet: {
		defaultNetwork: WALLET_CONNECTORS[0],
		defaultChain: WALLET_CHAINS[0],
	},
};

const slice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		selectDefaultChain(state, action) {
			state.wallet.defaultChain = action.payload;
		},
	},
});

// Reducer
export default slice.reducer;

// Actions
export const { selectDefaultChain } = slice.actions;
