import Metamask from "../images/icons/metamaskWallet.png";
import WalletConnect from "../images/icons/wallet-connect.svg";
import Avax from "../images/icons/wallet-avax.svg";
import Polygon from "../images/icons/wallet-polygon.svg";
import BSC from "../images/icons/wallet-bsc.svg";

export const WALLET_CONNECTORS = [
  {
    title: "Metamask",
    icon: Metamask,
    connectorId: "0x13881",
    priority: 1,
  },
  {
    title: "WalletConnect",
    icon: WalletConnect,
    connectorId: "0x38",
    priority: 2,
  },
  // {
  //   title: "Trust Wallet",
  //   icon: TrustWallet,
  //   connectorId: "0xa86a",
  //   priority: 3,
  // },
];
export const WALLET_CHAINS = [
  {
    value: process.env.REACT_APP_NFT_CHAIN_POLYGON,
    block: "polygon_test",
    label: "Polygon",
    icon: Polygon,
    token: "matic",
  },
  {
    value: process.env.REACT_APP_NFT_CHAIN_BSC,
    block: "bsc_test",
    label: "Binance",
    icon: BSC,
    token: "bnb",
  },
  {
    value: process.env.REACT_APP_NFT_CHAIN_AVAX,
    block: "avax_test",
    label: "Avalanche",
    icon: Avax,
    token: "avax",
  },

];
export const NFT_STATUS = {
  error: 0,
  reserved: 1,
  myReserved: 2,
  alreadyMinted: 3,
  notEnoughMoneyToReserved: 4,
  avForMintOrReserved: 5,
  onPending: 6,
  forbiden:10,
  free:11,
  waitingMine:14,
  waitingFree:15,
};
export const NFT_MINTABLE_TIMELIMIT = "Until properties is bought";
export const TRANSACTION_RECEIVER =
  "0x41bb4cec988d83e9f32860819e83f9D74b61BAA4";
