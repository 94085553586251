import React, { useCallback } from "react";
import {
  IconButton,
  Typography,
  Card,
  CardActions,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Icon } from "@iconify/react";
import { useSnackbar, SnackbarContent } from "notistack";
import infoFill from "@iconify-icons/eva/info-fill";
import alertCircleFill from "@iconify-icons/eva/alert-circle-fill";
import alertTriangleFill from "@iconify-icons/eva/alert-triangle-fill";
import checkmarkCircle2Fill from "@iconify-icons/eva/checkmark-circle-2-fill";
import './styles.scss'

function SnackbarIcon({ icon, color }) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: "flex",
        borderRadius: 1.5,
        alignItems: "center",
        justifyContent: "center",
        color: `${color}.main`,
        backgroundColor: "#2a2a2abd",
      }}
    >
      <Icon icon={icon} width={24} height={24} />
    </Box>
  );
}
const CustomSnackbar = React.forwardRef((props, ref) => {
  // const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  const getSnackbarIcon = () => {
    try {
      const variant = props.variant;
      if (!variant) return;
      let icon = null;
      let color = null;
      if (variant === "success") {
        icon = checkmarkCircle2Fill;
        color = "success";
      } else if (variant === "error") {
        icon = infoFill;
        color = "error";
      } else if (variant === "warning") {
        icon = alertTriangleFill;
        color = "warning";
      } else if (variant === "info") {
        icon = alertCircleFill;
        color = "info";
      }
      return <SnackbarIcon icon={icon} color={color} />;
    } catch (error) {
      return null;
    }
  };
  const getMsg = () => {
    try {
      const msg = props.message;
      if (Array.isArray(msg)) {
        return (
          <Box sx={{display:"flex", alignItems:"center", color: "#fff", fontSize:"14px", fontFamily: "Aldrich"}}>
            {msg.map((it) => {
              return (
                <div key={it}>
                  <Typography variant="body2" 
                  // className={classes.typography}
                  sx={{color: "#fff", fontSize:"14px", fontFamily: "Aldrich"}}
                  >
                    {it}
                  </Typography>
                </div>
              );
            })}
          </Box>
        );
      } else {
        return (
          <Typography variant="h4" 
          sx={{color: "#fff", fontSize:"14px", fontFamily: "Aldrich"}}
          // className={classes.typography}
          >
            {msg}
          </Typography>
        );
      }
      return <></>;
    } catch (error) {
      return <></>;
    }
  };
  return (
    <SnackbarContent ref={ref} message={props.message} style={{
       fontFamily: "Aldrich"
    }}>
      <Card sx={{ color: "#fff"}}>
        <CardActions>
          <Box sx={{display:"flex", alignItems:"center", color: "#fff", fontSize:"14px", fontFamily: "Aldrich"}}>
            {getSnackbarIcon()}
            {getMsg()}
          </Box>

          <Box sx={{ marginLeft: "auto"}}>
            <IconButton 
              sx={{color: "#fff"}} 
              onClick={handleDismiss}>
              <CloseIcon />
            </IconButton>
          </Box>
        </CardActions>
      </Card>
    </SnackbarContent>
  );
});

export default CustomSnackbar;
