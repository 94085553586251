// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: "/auth",
  app: "/app",
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, "/login"),
    loginUnprotected: path(ROOTS.auth, "/login-unprotected"),
    register: path(ROOTS.auth, "/register"),
    registerUnprotected: path(ROOTS.auth, "/register-unprotected"),
    resetPassword: path(ROOTS.auth, "/forgot-password"),
    recoverPassword: path(ROOTS.auth, "/password-recover"),
    verify: path(ROOTS.auth, "/verify"),
  },
};

export const PATH_HOME = {
  components: "/components",
  dashboard: ROOTS.app,
  about: "/about",
  news: "/news",
};

export const PATH_APP = {
  root: ROOTS.app,
  home: {
    home: path('/'),
  },
  general: {
    root: path(ROOTS.app, "/dashboard"),
    dashboard: path(ROOTS.app, "/dashboard"),
    about: path(ROOTS.app, "/about"),
  },

  management: {
    user: {
      profile: path(ROOTS.app, "/profile"),
      money: path(ROOTS.app, "/money"),
      assets: path(ROOTS.app, "/assets"),
      external: path(ROOTS.app, "/user/external"),
      settings: path(ROOTS.app, "/settings-general"),
    },
  },
  market: {
    marketplace: path(ROOTS.app, "/marketplace"),
  },
  resource: {
    resource: path(ROOTS.app, "/rare-resources"),
  },
  immaterialresource: {
    immaterialresource: path(ROOTS.app, "/immaterial-resources"),
  },
  cityMarket: {
    cityMarket: path(ROOTS.app, "/city-market"),
  },
  ranking: {
     ranking: path(ROOTS.app, "/ranking"),
  },
  transaction: {
    transaction: path(ROOTS.app, "/transaction"),
  },
  leader: {
    leaderboard: path(ROOTS.app, "/leaderboard"),
  },
  about: {
    metaverse: path(ROOTS.app, "/about"),
    metaKey: path(ROOTS.app, "/about/meta-key"),
    metaTeam: path(ROOTS.app, "/about/meta-team"),
    earnMoney: path(ROOTS.app, "/about/earn-money"),
    earnCrypto: path(ROOTS.app, "/about/earn-crypto"),
    playKey: path(ROOTS.app, "/about/play-key"),
    playTeam: path(ROOTS.app, "/about/play-team"),
    learnHistory: path(ROOTS.app, "/about/learn-history"),
    learnSkills: path(ROOTS.app, "/about/learn-skills"),
  },
  admin: {
		view: path(ROOTS.app, '/admin/adminView'),
	},

  news: {
    home: path(ROOTS.app, "/news"),
  },
  nft: {
    home: path(ROOTS.app, "/nft"),
     mynft: path(ROOTS.app, "/mynft"),
    nftPosibility: path(ROOTS.app, "/nft-posibility"),
  },
  staking: {
    home: path(ROOTS.app, "/staking"),
  },
  specialItems: {
    specialItems: path(ROOTS.app, "/special-items"),
  },
  units: {
    units: path(ROOTS.app, "/units"),
  },
  ressources: {
    ressources: path(ROOTS.app, "/rare-resources"),
  },
  enigma: {
    enigma: path(ROOTS.app, "/enigma"),
  },
  techtree: {
    techtree: path(ROOTS.app, "/techTree"),
  },
  privacyPolicy: {
    privacyPolicy: path(ROOTS.app, "/privacy-policy"),
  },
  cookies: {
    cookies: path(ROOTS.app, "/cookies"),
  },
};
