import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getTilePrice } from '../redux/slices/drawer';
import { getProfile, updateUserBank,getUserMoney } from '../redux/slices/user';
import axios from 'axios';
import configData from '../config.json';
import { Icon } from '@iconify/react';
import closeFill from '@iconify-icons/eva/close-fill';
import { useTranslation } from 'react-i18next';
import {
	IconButton,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Box,
	Grow,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CustomSnackbar from '../components/snackbar/CustomSnackbar';

export const MapContext = React.createContext();
const COOKIE_TIMEZONE = 'timezone-opened';
const MapProvider = ({ ...props }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { initialized, user } = useSelector((state) => state.authJwt);
	const [openDialog, setOpenDialog] = useState(false);
	const [alreadyOpenDialog, setAlreadyOpenDialog] = useState(false);

	const isOpenedDialog = localStorage.getItem(COOKIE_TIMEZONE);
	// useEffect(() => {
	// 	if (isOpenedDialog) {
	// 		setAlreadyOpenDialog(true);
	// 	} else {
	// 		setAlreadyOpenDialog(false);
	// 	}
	// }, [isOpenedDialog]);

	useEffect(() => {
		if (!user || !user.id) {
			return;
		}
		dispatch(getProfile(user.id));
		dispatch(getTilePrice(2));
	}, [dispatch, user]);
	const handleopenDialog = () => {
		if (!user || !user.id) return;
		if (alreadyOpenDialog) return;
		setOpenDialog(true);
		setAlreadyOpenDialog(true);
		localStorage.setItem(COOKIE_TIMEZONE, true);
	};

	const handleClose = () => {
		setOpenDialog(false);
	};

	

	const configureProperty = async (listGeo, values) => {
		try {
			if (!listGeo || Object.keys(listGeo).length === 0) {
				enqueueSnackbar('Nothing selected', {
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},

					variant: 'warning',
					action: (key) => (
						<IconButton size="small" onClick={() => closeSnackbar(key)}>
							<Icon icon={closeFill} />
						</IconButton>
					),
				});
				return;
			}
			var rank;
	
			if(values.isAbsolute==1){
				rank="Absolute"
			}
			if(values.isUltimate==1){
				rank="Ultimate"
			}
			if(values.isPremium==1){
				rank="Premium"
			}
			const response = await axios.post(configData.SERVER_URL + configData.configProperty, {
				listGeometry: listGeo,
				//listGeometry: aesc2,
				name:values.name,
				comment: values.comment,
				link:values.link,
				country:values.country,
				city:values.city,
				rank:rank,
			
				category1:values.category1,
				category2:values.category2,
				category3:values.category3,
				isVisible:values.isVisible,
				isMysterious:values.isMysterious,
				ownerUUID: user['id'],
			});
		
			if (response.data === 'Done') {

				enqueueSnackbar('Transaction successful', {
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},
					autoHideDuration: 10000,
					TransitionComponent: Grow,
					style: { top: '100px' },
					content: (id, msg) => <CustomSnackbar id={id} message={msg} variant="success" />,
				});
			}
			else{

				enqueueSnackbar('Not authorize!', {
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},

					variant: 'error',
					action: (key) => (
						<IconButton size="small" onClick={() => closeSnackbar(key)}>
							<Icon icon={closeFill} />
						</IconButton>
					),
				});

			}
		} catch (error) {
			console.error(error, listGeo);
			enqueueSnackbar("An Error occured with the request, couldn't do that !", {
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'left',
				},

				variant: 'error',
				action: (key) => (
					<IconButton size="small" onClick={() => closeSnackbar(key)}>
						<Icon icon={closeFill} />
					</IconButton>
				),
			});
		}
	};
	const instantBuyTiles = async (listGeo, values) => {
		
		try {
			if (!listGeo || Object.keys(listGeo).length === 0) {
				enqueueSnackbar('Nothing selected', {
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},

					variant: 'warning',
					action: (key) => (
						<IconButton size="small" onClick={() => closeSnackbar(key)}>
							<Icon icon={closeFill} />
						</IconButton>
					),
				});
				return;
			}
			var referal = '';
			if (localStorage.getItem('referal')) {
				referal = localStorage.getItem('referal');
			}
			const val = {
				description: '',
				isano: 0,
				country: values,
				ownerUUID: user['id'],
				usingpc: 0,
				referal,
				...values,
			};
			await buyTiles(val, listGeo);
		} catch (error) {
			console.error(error, listGeo);
		}
	};
	const buyTiles = async (values, listGeo) => {
		try {
			
			//var aesc2=CryptoAES.encrypt(JSON.stringify(listGeo),process.env.REACT_APP_AESSECRET).toString()
			const response = await axios.post(configData.SERVER_URL + configData.buytiles, {
				listGeometry: listGeo,
				//listGeometry: aesc2,
				description: values.description,
				ownerUUID: user['id'],
				choosenFlag: values.country,
				isAnonymous: values.isano,
				usingPC: values.usingpc,
				referal: values.referal,
			});
			console.log(response.data)

			if (response.data.return === 'done') {
				dispatch(getProfile(user['id']));
			
				const um = response.data.um;
				const newAmount = { money: um[0], pc: um[3], pr: um[2], rekk: um[1] };
				dispatch(updateUserBank(newAmount));
				dispatch(getUserMoney(user['id']));
				enqueueSnackbar(response.data.message, {
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},
					autoHideDuration: 10000,
					TransitionComponent: Grow,
					style: { top: '100px' },
					content: (id, msg) => <CustomSnackbar id={id} message={msg} variant="success" />,
				});
			} else {
				if (response.data === 'not enough money') {
					enqueueSnackbar('Not enough money!', {
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						autoHideDuration: 10000,
						TransitionComponent: Grow,
						style: { top: '100px' },
						content: (id, msg) => <CustomSnackbar id={id} message={msg} variant="error" />,
					});
				} else if (response.data === 'Not enough money or credits') {
					enqueueSnackbar('Not enough money or credits!', {
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},

						variant: 'error',
						action: (key) => (
							<IconButton size="small" onClick={() => closeSnackbar(key)}>
								<Icon icon={closeFill} />
							</IconButton>
						),
					});
				} else if (response.data === 'Not enough Credits') {
					enqueueSnackbar('Not enough Credits!', {
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},

						variant: 'error',
						action: (key) => (
							<IconButton size="small" onClick={() => closeSnackbar(key)}>
								<Icon icon={closeFill} />
							</IconButton>
						),
					});
				} else if (response.data === "Something happened, you couldn't buy this") {
					enqueueSnackbar("Something happened during your bought, couldn't buy this!", {
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						autoHideDuration: 10000,
						TransitionComponent: Grow,
						style: { top: '100px' },
						content: (id, msg) => <CustomSnackbar id={id} message={msg} variant="error" />,
					});
				} else if (response.data === 'You buy too fast, slow down') {
					enqueueSnackbar('You buy too quickly, slow down!', {
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						autoHideDuration: 10000,
						TransitionComponent: Grow,
						style: { top: '100px' },
						content: (id, msg) => <CustomSnackbar id={id} message={msg} variant="error" />,
					});
				} else if (response.data === 'Too quick to be normal') {
					enqueueSnackbar('You buy too quickly, slow down!', {
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						autoHideDuration: 10000,
						TransitionComponent: Grow,
						style: { top: '100px' },
						content: (id, msg) => <CustomSnackbar id={id} message={msg} variant="error" />,
					});
				} else if (response.data === 'Tile list empty, or too heavy') {
					enqueueSnackbar('Tile list empty, or too heavy!', {
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						autoHideDuration: 10000,
						TransitionComponent: Grow,
						style: { top: '100px' },
						content: (id, msg) => <CustomSnackbar id={id} message={msg} variant="error" />,
					});
				} else if (response.data === 'Wrong tile list') {
					enqueueSnackbar('Wrong tile list!', {
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						autoHideDuration: 10000,
						TransitionComponent: Grow,
						style: { top: '100px' },
						content: (id, msg) => <CustomSnackbar id={id} message={msg} variant="error" />,
					});
				} else if (response.data === 'Property not available') {
					enqueueSnackbar('Property not available!', {
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						autoHideDuration: 10000,
						TransitionComponent: Grow,
						style: { top: '100px' },
						content: (id, msg) => <CustomSnackbar id={id} message={msg} variant="error" />,
					});
				} else if (response.data === 'Tile was already bought in your selection') {
					enqueueSnackbar('Tile was already bought in your selection!', {
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						autoHideDuration: 10000,
						TransitionComponent: Grow,
						style: { top: '100px' },
						content: (id, msg) => <CustomSnackbar id={id} message={msg} variant="error" />,
					});
				} else if (response.data === 'Some tiles was bought during the processing') {
					enqueueSnackbar('Some tiles was bought during the processing!', {
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						autoHideDuration: 10000,
						TransitionComponent: Grow,
						style: { top: '100px' },
						content: (id, msg) => <CustomSnackbar id={id} message={msg} variant="error" />,
					});
				} else if (response.data === 'Some tiles was not available') {
					enqueueSnackbar('Some tiles was not available!', {
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						autoHideDuration: 10000,
						TransitionComponent: Grow,
						style: { top: '100px' },
						content: (id, msg) => <CustomSnackbar id={id} message={msg} variant="error" />,
					});
				} else if (response.data === 'Timezone not open yet') {
					enqueueSnackbar('Timezone not open yet!', {
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						autoHideDuration: 10000,
						TransitionComponent: Grow,
						style: { top: '100px' },
						content: (id, msg) => <CustomSnackbar id={id} message={msg} variant="error" />,
					});
				} else if (response.data === 'You spent your weekly limit') {
					enqueueSnackbar('You spent your weekly limit!', {
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						autoHideDuration: 10000,
						TransitionComponent: Grow,
						style: { top: '100px' },
						content: (id, msg) => <CustomSnackbar id={id} message={msg} variant="error" />,
					});
				}
				else if (response.data === 'Over Credits Limit') {
					enqueueSnackbar('Over Credits Limit!', {
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						autoHideDuration: 10000,
						TransitionComponent: Grow,
						style: { top: '100px' },
						content: (id, msg) => <CustomSnackbar id={id} message={msg} variant="error" />,
					});
				}
			}
			dispatch(getProfile(user['id']));
		} catch (error) {
			console.error(error);
			enqueueSnackbar("An Error occured with the request, couldn't buy this!", {
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'left',
				},

				variant: 'error',
				action: (key) => (
					<IconButton size="small" onClick={() => closeSnackbar(key)}>
						<Icon icon={closeFill} />
					</IconButton>
				),
			});
		}
	};
	const forceBuy = async (propertyUUID, user, isAnonymous, upat) => {
		try {
			const response = await axios.post(configData.SERVER_URL + configData.buyf, {
				propertyUUID: propertyUUID,
				buyerUUID: user.id,
				isAnonymous: isAnonymous,
				lock: 0,
				upat: upat,
			});
			console.log(response.data)
			if (response.data.message === 'Not enough money') {
				enqueueSnackbar('Not enough money!', {
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},

					variant: 'error',
					action: (key) => (
						<IconButton size="small" onClick={() => closeSnackbar(key)}>
							<Icon icon={closeFill} />
						</IconButton>
					),
				});
			} else if (response.data.message === 'Too late already sold') {
				enqueueSnackbar('Too late already sold!', {
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},

					variant: 'error',
					action: (key) => (
						<IconButton size="small" onClick={() => closeSnackbar(key)}>
							<Icon icon={closeFill} />
						</IconButton>
					),
				});
			} else if (response.data.message === 'Invalid use') {
				enqueueSnackbar('Invalid use!', {
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},

					variant: 'error',
					action: (key) => (
						<IconButton size="small" onClick={() => closeSnackbar(key)}>
							<Icon icon={closeFill} />
						</IconButton>
					),
				});
			} else if (response.data.message === 'Owner already changed for this property') {
				enqueueSnackbar('Owner already changed for this property!', {
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},

					variant: 'error',
					action: (key) => (
						<IconButton size="small" onClick={() => closeSnackbar(key)}>
							<Icon icon={closeFill} />
						</IconButton>
					),
				});
			} else if (response.data.message === 'Property allready changed') {
				enqueueSnackbar('Property allready changed!', {
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},

					variant: 'error',
					action: (key) => (
						<IconButton size="small" onClick={() => closeSnackbar(key)}>
							<Icon icon={closeFill} />
						</IconButton>
					),
				});
			} else if (response.data.message === 'Property not exist') {
				enqueueSnackbar('Property not exist!', {
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},

					variant: 'error',
					action: (key) => (
						<IconButton size="small" onClick={() => closeSnackbar(key)}>
							<Icon icon={closeFill} />
						</IconButton>
					),
				});
			}  else if (response.data.message === 'Not enough token') {
				enqueueSnackbar('Not enough token!', {
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},

					variant: 'error',
					action: (key) => (
						<IconButton size="small" onClick={() => closeSnackbar(key)}>
							<Icon icon={closeFill} />
						</IconButton>
					),
				});
			}  
			else if (response.data.message === 'Not enough token') {
				enqueueSnackbar('Not enough token!', {
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},

					variant: 'error',
					action: (key) => (
						<IconButton size="small" onClick={() => closeSnackbar(key)}>
							<Icon icon={closeFill} />
						</IconButton>
					),
				});
			}
			else if (response.data.message === 'Too much trying to buy on same time') {
				enqueueSnackbar('Too much trying to buy on same time!', {
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},

					variant: 'error',
					action: (key) => (
						<IconButton size="small" onClick={() => closeSnackbar(key)}>
							<Icon icon={closeFill} />
						</IconButton>
					),
				});
			}
			else if (response.data.message === 'An error occured') {
				enqueueSnackbar('An error occured!', {
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},

					variant: 'error',
					action: (key) => (
						<IconButton size="small" onClick={() => closeSnackbar(key)}>
							<Icon icon={closeFill} />
						</IconButton>
					),
				});
			}
			else {
				dispatch(getProfile(user['id']));
				//
				if(response.data.um==null || response.data.um==undefined){
					enqueueSnackbar('Unknown error : '+JSON.stringify(response.data), {
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
		
						variant: 'error',
						action: (key) => (
							<IconButton size="small" onClick={() => closeSnackbar(key)}>
								<Icon icon={closeFill} />
							</IconButton>
						),
					});
				}
				else{
					const um = response.data.um;
					const newAmount = { money: um[0], pc: um[3], pr: um[2], rekk: um[1] };
					dispatch(updateUserBank(newAmount));
					dispatch(getUserMoney(user['id']));
					enqueueSnackbar('Buy with success', {
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						variant: 'success',
						action: (key) => (
							<IconButton size="small" onClick={() => closeSnackbar(key)}>
								<Icon icon={closeFill} />
							</IconButton>
						),
					});
				}

			}
		} catch (error) {
			console.log(error)
			enqueueSnackbar('Unknown error : '+JSON.stringify(error), {
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'left',
				},

				variant: 'error',
				action: (key) => (
					<IconButton size="small" onClick={() => closeSnackbar(key)}>
						<Icon icon={closeFill} />
					</IconButton>
				),
			});
		}
	};
	const saveChangesTilesBuyed = async (values) => {
		try {
			const response = await axios.post(configData.SERVER_URL + configData.updateProperties, {
				puuid: values.puuid,
				location: values.location,
				description: values.description,
				country: values.country,
				uuuid: values.uuuid,
			});

			if (response.data === 'done') {
				enqueueSnackbar('Property update approved!', {
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},

					variant: 'success',
					action: (key) => (
						<IconButton size="small" onClick={() => closeSnackbar(key)}>
							<Icon icon={closeFill} />
						</IconButton>
					),
				});
			} else if (response.data === 'Invalid use') {
				enqueueSnackbar('Invalid use!', {
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},

					variant: 'error',
					action: (key) => (
						<IconButton size="small" onClick={() => closeSnackbar(key)}>
							<Icon icon={closeFill} />
						</IconButton>
					),
				});
			}
		} catch (error) {
			console.error(error);
			enqueueSnackbar("Error happened, couldn't change this!", {
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'left',
				},

				variant: 'error',
				action: (key) => (
					<IconButton size="small" onClick={() => closeSnackbar(key)}>
						<Icon icon={closeFill} />
					</IconButton>
				),
			});
		}
	};
	const getWarnMaxTiles = (maxtiles) => {
		enqueueSnackbar(
			t('warn_max_tiles', { maxtiles: maxtiles }, `Maximum tile is ${maxtiles} , you reach that limit`),
			{
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
				autoHideDuration: 5000,
				style: { top: '200px', width: '350px' },
				variant: 'warning',
				action: (key) => (
					<IconButton size="small" onClick={() => closeSnackbar(key)}>
						<Icon icon={closeFill} />
					</IconButton>
				),
			}
		);
	};
	const downloadMapImg = () => {
		try {
			axios({
				url: `/static/brand/EARTHIUM_MAP_V4.pdf`,
				method: 'GET',
				responseType: 'blob', // important
			}).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `EARTHIUM_MAP_V4.pdf`);
				document.body.appendChild(link);
				link.click();
			});
		} catch (error) {
			console.error(error);
		}
	};
	if (!initialized) return null;
	return (
		<MapContext.Provider
			value={{ getWarnMaxTiles, saveChangesTilesBuyed, forceBuy, buyTiles, instantBuyTiles,configureProperty, handleopenDialog }}
		>
			<Dialog
				fullWidth
				maxWidth="lg"
				open={openDialog}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogTitle id="responsive-dialog-title">
					{t(
						'msg_timezone_dialog',
						{
							next: '30 april 3PM GMT TZ+3 and TZ -9',
							open: 'All the others',
						},
						'Timezones open: {{open}}. Next: {{next}}, ...'
					)}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
							{t('msg_timezone_download', 'You can download the high quality image version here')}
							<IconButton
								aria-label="Download"
								onClick={() => {
									downloadMapImg();
								}}
								sx={{ ml: 3, flexShrink: 1 }}
							>
								<DownloadIcon />
							</IconButton>
						</Box>
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<img src="/static/brand/map_timezones_av.jpg" alt="" />
						</Box>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} autoFocus>
						{t('close', 'Close')}
					</Button>
				</DialogActions>
			</Dialog>
			{props.children}
		</MapContext.Provider>
	);
};
export default MapProvider;
