import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  selectDefaultChain } from '../redux/slices/settings';

// ----------------------------------------------------------------------

function useSettings() {
	const dispatch = useDispatch();
	const { wallet } = useSelector((state) => state.settings);

	const handleChangeDefaultChain = useCallback((data) => dispatch(selectDefaultChain(data)), [dispatch]);

	return {
		wallet,
		selectDfChain: handleChangeDefaultChain,
	};
}

export default useSettings;
