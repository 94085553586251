import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import configData from '../../config.json';
// ----------------------------------------------------------------------
const initialState = {
	isLoading: false,
	error: false,
	datas: [],
	tokenType: '',
	anonymousList: null,
	landmarksInformations:null,
	informations: null,
	geojson:[],
	geojsonFinal:[],
	newlocations: [],
	locations: [],
	countryList: [],
	cityList: [],
	nbTile: 0,
	tilePrice: 0,
	tileId: 0,
	isInstantBuy: false,
	thumbnail: '',
	mapd: '',
	explorer: null,
	c1: '',
	c2: '',
	c3: '',
	tamount: 0,
	listGeometry: [],
	transactionList: [],
	transaction$: [],
	transactionRekk: [],
	transactionCredit: [],
	transactionRP: [],
	treasureChestList: [],
	timezone: [],
	enigma: [],
	rekkFactoryInvest: 0,
	rekkFactoryRevenue: 0,
	rekkFactoryBoost: 0,
	propertiesProduction: [],
	propertiesScore: [],
	invalidDatas: {},
	adminListUser:[],
	creditLimit:{},
	adminMode: 0
};

const slice = createSlice({
	name: 'drawer',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true;
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},

		// GET DATA TABLE
		getDatasSuccess(state, action) {
			state.isLoading = false;
			state.datas = action.payload;
			state.invalidDatas = {};
		},
		// GET DATA TABLE
		getDatasError(state, action) {
			state.isLoading = false;
			state.datas = [];
			state.invalidDatas = action.payload;
		},

		getcreditLimitSuccess(state, action) {
			state.isLoading = false;
		
			state.creditLimit = action.payload;
		},
		setGeoJSONSuccess(state,action){
			state.isLoading = false;
			state.geojson = action.payload;

		},
		setGeoJSONClickedSuccess(state,action){
			state.isLoading = false;
			state.geojsonFinal = action.payload;

		},
		
		getLandmarkPropertiesSuccess(state, action) {
			state.isLoading = false;
			state.landmarksInformations = action.payload;
		},
		// GET VALUES DRAWER 1
		getValuesSuccess(state, action) {
			state.isLoading = false;
			state.informations = action.payload;
		},
		// GET VALUES DRAWER 1
		resetValuesSuccess(state, action) {
			state.informations = null;
			state.anonymousList = null;
		},

		getTreasureChestSuccess(state, action) {
			state.isLoading = false;
			state.treasureChestList = action.payload;
		},

		getAnonymousListSuccess(state, action) {
			state.isLoading = false;
			state.anonymousList = action.payload;
		},
		// GET VALUES DRAWER 1
		getLocationSuccess(state, action) {
			state.isLoading = false;
			state.locations = action.payload;
		},
		getNewLocationSuccess(state, action) {
			state.isLoading = false;
			state.newlocations = action.payload;
		},
		getTokenTypeSuccess(state, action) {
			state.isLoading = false;
			state.tokenType = action.payload;
		},
		getCountriesSuccess(state, action) {
			state.isLoading = false;
			state.countryList = action.payload;
		},
		getCouponSuccess(state, action) {
			state.isLoading = false;
			state.c1 = action.payload[2];
			state.c2 = action.payload[3];

			state.c3 = action.payload[4];
			state.tamount = action.payload[6];
		},
		getCitiesSuccess(state, action) {
			state.isLoading = false;
			state.cityList = action.payload;
		},

		// Tile Price
		tilePriceSuccess(state, action) {
			state.isLoading = false;
			state.tilePrice = 1;
		},
		// Tile Price

		getlistGeometrySuccess(state, action) {
			state.isLoading = false;
			state.listGeometry = action.payload;
		},
		nbTileSuccess(state, action) {
			state.isLoading = false;
			state.nbTile = action.payload;
		},
		adminModeSuccess(state, action) {
			state.isLoading = false;
			state.adminMode = action.payload;
		},
		// Tile Price
		mapSuccess(state, action) {
			state.isLoading = false;
			state.mapd = action.payload;
		},
		// Tile Price
		instantBuySuccess(state, action) {
			state.isLoading = false;
			state.isInstantBuy = action.payload;
		},
		// Tile Price
		thumbnailBuySuccess(state, action) {
			state.isLoading = false;
			state.thumbnail = action.payload;
		},
		getTransactionUserSuccess(state, action) {
			state.isLoading = false;
			state.transactionList = action.payload;
		},
		getTransaction$Success(state, action) {
			state.isLoading = false;
			state.transaction$ = action.payload;
		},
		getTransactionRekkSuccess(state, action) {
			state.isLoading = false;
			state.transaction$ = action.payload;
		},
		getTransactionCreditSuccess(state, action) {
			state.isLoading = false;
			state.transaction$ = action.payload;
		},
		getTransactionRPSuccess(state, action) {
			state.isLoading = false;
			state.transaction$ = action.payload;
		},
		// update
		updatePropertiesSuccess(state, action) {
			state.isLoading = false;
		},
		getTZSuccess(state, action) {
			state.isLoading = false;
			state.timezone = action.payload;
		},
		getEnigmaSuccess(state, action) {
			state.isLoading = false;
			state.enigma = action.payload;
		},
		getRekkFactorySuccess(state, action) {
			state.isLoading = false;
		
			state.rekkFactoryInvest = action.payload[0];
			state.rekkFactoryRevenue = action.payload[1];
			state.rekkFactoryBoost = action.payload[2];
		},
		getPropertiesProdSuccess(state, action) {
			state.isLoading = false;
			state.propertiesProduction = action.payload.totalProduction[0];
			state.propertiesScore = action.payload.totalScore[0];
		},

		getExploringTileSuccess(state, action) {
			state.isLoading = false;
			state.explorer = action.payload.explorer;
			state.tileId = action.payload.tileId;
		},
		getInstantBuySuccess(state, action) {
			state.isLoading = false;
		},


getListUserSuccess(state, action) {
	state.isLoading = false;
	state.adminListUser = action.payload;
},

	},
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions; /* eslint-disable-line */

export function getDatas(listGeometry, nb, adminMode) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getCentroid, {
				list: listGeometry,
				adminMode: adminMode
			});
			const centroid = response.data.centroid;
			/* For test if (centroid[0].length == 6) {
				centroid[2] = 0;
				centroid[3] = 0;
			} */
			/** 
response param : isCountryOpen. values :0,1, 2  error if 0*/
			const isCountryOpen = response.data.ico;

			const isError = centroid[2] === 0 || centroid[3] === 0 || isCountryOpen === 0;

			if (isError) {
				const objErr = {
					timezoneNotOpened: centroid[2] === 0,
					selectionSoFar: centroid[3] === 0,
					isCountryOpen: isCountryOpen>0,
				};
				dispatch(slice.actions.getDatasError(objErr));
			} else {
				console.log(response.data)
				dispatch(slice.actions.getNewLocationSuccess(response.data.newlocation));
				dispatch(slice.actions.getTokenTypeSuccess(response.data.tokenType));
				dispatch(slice.actions.getLocationSuccess(response.data.location.features));
				dispatch(slice.actions.getlistGeometrySuccess(listGeometry));
				dispatch(slice.actions.getDatasSuccess(response.data.centroid));
				dispatch(slice.actions.nbTileSuccess(nb));
			}
		} catch (error) {
			dispatch(slice.actions.getNewLocationSuccess({}));
			dispatch(slice.actions.getTokenTypeSuccess({}));
			dispatch(slice.actions.getLocationSuccess({}));
			dispatch(slice.actions.getlistGeometrySuccess({}));
			dispatch(slice.actions.getDatasSuccess({}));
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getNB(nb) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			dispatch(slice.actions.nbTileSuccess(nb));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function setAdminMode(mode) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			dispatch(slice.actions.adminModeSuccess(mode));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getMap(map) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			dispatch(slice.actions.mapSuccess(map));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function isInstantBy(isornot) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			dispatch(slice.actions.instantBuySuccess(isornot));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getTilePrice(id) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getSettings, {
				id: id,
			});

			dispatch(slice.actions.tilePriceSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getThumbnail(dd, puuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.upimg, {
				dd: dd,
				puuid: puuid,
			});
			// console.log(response);
			dispatch(slice.actions.thumbnailBuySuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
// ----------------------------------------------------------------------

export function getTransactionUser(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(
				configData.SERVER_URL + configData.getTransactionUser,

				{ uuid: uuid }
			);
			dispatch(slice.actions.getTransactionUserSuccess(response.data.transaction));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getTransaction$(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(
				configData.SERVER_URL + configData.transaction,

				{ uuid: uuid }
			);
			dispatch(slice.actions.getTransaction$Success(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getTransactionRekk(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(
				configData.SERVER_URL + configData.transactionRekk,

				{ uuid: uuid }
			);
			dispatch(slice.actions.getTransactionRekkSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getTransactionCredit(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(
				configData.SERVER_URL + configData.transactionPC,

				{ uuid: uuid }
			);
			dispatch(slice.actions.getTransactionCreditSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getTransactionRP(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(
				configData.SERVER_URL + configData.transactionRP,

				{ uuid: uuid }
			);
			dispatch(slice.actions.getTransactionRPSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getAnonymousList(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.get(configData.SERVER_URL + configData.getAnonymousList);

			dispatch(slice.actions.getAnonymousListSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function resetValues() {
	return async (dispatch) => {
		dispatch(slice.actions.resetValuesSuccess());
	};
}

export function setGeoJSON(data) {
	return async (dispatch) => {
		dispatch(slice.actions.setGeoJSONSuccess(data));
	};
}

export function setGeoJSONClicked(data) {
	console.log("setGeoJSONClicked:",data)
	return async (dispatch) => {
		dispatch(slice.actions.setGeoJSONClickedSuccess(data));
	};
}


export function getValues(pid, isAnonymous) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getProperties, {
				pid: pid,
				isAnonymous: isAnonymous,
			});
			dispatch(slice.actions.getValuesSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getLandmarkProperties(tid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getLandmarkProperties, {
				tid: tid,type:1
			});
			dispatch(slice.actions.getLandmarkPropertiesSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getcreditLimit(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.creditLimit, {
				uuid: uuid
			});
			dispatch(slice.actions.getcreditLimitSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getEnigma(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(
				configData.SERVER_URL + configData.getEnigma,

				{ uuid: uuid }
			);
			dispatch(slice.actions.getEnigmaSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getLocation(lng, lat) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(
				configData.SERVER_URL + configData.geocoding,

				{ lng: lng, lat: lat }
			);
			//console.log('get location done');
			dispatch(slice.actions.getLocationSuccess(response.data.features));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getCoupon(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.get(configData.SERVER_URL + configData.getCoupon);
			dispatch(slice.actions.getCouponSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getTZ(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getTZ, {
				uuid: uuid,
			});
			//console.log(response)
			dispatch(slice.actions.getTZSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getTreasureChest(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.get(configData.SERVER_URL + configData.getTc);
			dispatch(slice.actions.getTreasureChestSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getFactory(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.get(configData.SERVER_URL + configData.getFactory);

			dispatch(slice.actions.getRekkFactorySuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getPropertiesProd(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.get(configData.SERVER_URL + configData.getPropertiesProd);
			dispatch(slice.actions.getPropertiesProdSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getCountryList() {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.get(configData.SERVER_URL + configData.getCountryList);

			dispatch(slice.actions.getCountriesSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getCityFilter(country) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getCityFilter, { country: country });

			dispatch(slice.actions.getCitiesSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function updateProperties(values) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			await axios.post(configData.SERVER_URL + configData.updateProperties, {
				uuid: values.uuid,
				location: values.location,
				description: values.description,
				country: values.country,
			});
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getExploringTile(values, useruuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getExploringTile, {
				tileId: values,
				useruuid: useruuid,
			});
			var payload = {
				explorer: response.data,
				tileId: values,
			};

			dispatch(slice.actions.getExploringTileSuccess(payload));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getListUser(uuid) {
	return async (dispatch) => {
		dispatch(slice.actions.startLoading());
		try {
			const response = await axios.post(configData.SERVER_URL + configData.getUserList, { uuid: uuid });
			dispatch(slice.actions.getListUserSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
